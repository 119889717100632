import { ReactWrapper } from '$pages/common/react-wrapper';
import { AuthenticationService } from '$services/authenticationService';
import Qr from './qr.react';
import { autoinject } from 'aurelia-framework';

@autoinject
export class QrWrapper extends ReactWrapper {
  constructor(authService: AuthenticationService) {
    super(Qr, { authService });
  }
}
