import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import React, { FC, useEffect, useState } from 'react';
import {
  CheckSimOperationStatusDocument,
  EnqueueSimOperationDocument,
  SimOperation,
  SimOperationEnum, 
  SimStatusByControllerSerialQuery
} from "$typings/graphql-codegen";
import BuidDropdown from "$components/dropdowns/buid-dropdown/buid-dropdown.react";
import { mutate, useQuery } from "$lib/hooks/fetch-utillities";
import { ensureNumber } from "$lib/numberHelpers";
import { getSession } from "../../../config/sessionService";
import ErrorText from "$components/texts/error-text/error-text.react";
import {ensureDate, formatDateTime} from "$lib/dateHelpers";
import { userHasFeature } from "$lib/authorizationHelper";
import { features } from "../../../config";
import { AccessLevel } from "$interfaces/enums";
import "../qr.css"
import { Button, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import Skeleton from '$components/placeholders/skeleton/skeleton';

interface IQrActivateProps {
  data?: SimStatusByControllerSerialQuery;
  onClose?: () => void;
}

const QrActivate: FC<IQrActivateProps> = ({ data, onClose, }) => {
  const [t] = useCaseInsensitiveTranslation();
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [inProgessOrFailed, setInProgessOrFailed] = useState(false);
  const [overrideBuid, setOverrideBuid] = useState(false);
  const [overriddenBuid, setOverriddenBuid] = useState<number | undefined>(undefined);
  const [error, setError] = useState<string>();
  const { data: simOperationState, revalidate, isRevalidating, loading } = useQuery(CheckSimOperationStatusDocument, { icc: data?.simStatusByControllerSerial.simIcc ?? '' });

  const session = getSession();
  const isLoggedInWithAccess = session.userIsLoggedIn && userHasFeature(session, features.qrApp, AccessLevel.Write);

  useEffect(() => {
    if (!isLoggedInWithAccess) {
      setError("You don't have access to activate SIM cards. Please contact Yara Telemetry support.");
    }
  }, [data]);

  useEffect(() => {
    
    if (!simOperationState || isRevalidating) return

    if (simOperationState.checkSimOperationStatus?.state === "IN_PROGRESS" || simOperationState.checkSimOperationStatus?.state === "NOT_STARTED") {
      //Call again in 5 seconds
      setTimeout(() => revalidate && revalidate(), 5000);
      simOperationState.checkSimOperationStatus?.state === "IN_PROGRESS" && setInProgessOrFailed(true)
    } else if (simOperationState.checkSimOperationStatus?.state === "FINISHED") {
      setProcessing(false);
      setSuccess(true);
      setInProgessOrFailed(false)
    } else if (simOperationState.checkSimOperationStatus?.state === "FAILED") {
      setProcessing(false);
      setInProgessOrFailed(true)
      setError("SIM activation failed, contact Yara Telemetry support for further help.")
    }
  }, [simOperationState, isRevalidating]);

  async function activateSim() {
    setProcessing(true);

    const buidId = overriddenBuid ? overriddenBuid : data?.simStatusByControllerSerial.buidId ?? 0;
    const session = getSession();

    const simOperation: SimOperation = {
      Operation: SimOperationEnum.Activate,
      ICC: data?.simStatusByControllerSerial.simIcc ?? '',
      UserId: session.currentUser.userId,
      BuidId: buidId
    };

    //Perform activation request
    const res = await mutate(EnqueueSimOperationDocument, { simOperation: simOperation }, true);

    if (!res.enqueueSimOperation?.success) {
      setProcessing(false);
      setError(res.enqueueSimOperation?.message);
    }
  }

  if (loading) { 
    return <Skeleton rowCount={3}/>
  }

  return (
    <>
      {error && <ErrorText>{error}</ErrorText>}

      <div className='flex column gap-1'>
      {simOperationState && simOperationState.checkSimOperationStatus.operationId !== '0' && (
          <Table>
            <TableBody >

          <TableRow>
            <TableCell sx={{padding: "6px 0"}}>Last check</TableCell>
            <TableCell sx={{padding: "6px 0"}} align='right'>{formatDateTime(new Date(), "dd.MM.yyyy HH:mm:ss", undefined)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{padding: "6px 0"}}>Job created at</TableCell>
            <TableCell sx={{padding: "6px 0"}} align='right'>{formatDateTime(ensureDate(simOperationState.checkSimOperationStatus.createdAt ?? new Date()), "dd.MM.yyyy HH:mm:ss", undefined)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{padding: "6px 0"}} >Job state</TableCell>
            <TableCell sx={{padding: "6px 0"}} align='right'>{simOperationState.checkSimOperationStatus.state}</TableCell>
          </TableRow>
            </TableBody>
        </Table>
      )}

          {simOperationState && simOperationState.checkSimOperationStatus?.state == "NOT_STARTED" && (
            <div className='flex row fill-width jsb aicenter gap-1'>
              <label style={{ width: 100 }}>BUID</label>
              {
                <div className='fill-width'>

                  <BuidDropdown
                    className='fill-width'
                    disabled={!overrideBuid}
                    includeResponsibleBuid={true}
                    itemClicked={(b) => setOverriddenBuid(ensureNumber(b.buidId ?? 0))}
                    selected={overriddenBuid?.toString() ?? data?.simStatusByControllerSerial?.buidId?.toString() ?? undefined} />
                </div>
              }
              <Button type="button" variant="outlined" sx={{height: "44px", minWidth: "44px"}} onClick={() => setOverrideBuid(!overrideBuid)} ><Icon name={overrideBuid ? "fa-unlock" : "fa-lock"} /></Button>
            </div>
          )}
          {!success && !inProgessOrFailed  && (
            <>
              <Button variant="contained" fullWidth startIcon={<Icon name={processing ? 'fa-spinner fa-pulse' : 'fa-check'} />} onClick={activateSim} disabled={processing}>
                {t('UI_SimManagement_SimDetails_SierraWireless_Actions_Activate_Button')}
              </Button>

              <Button variant="outlined" fullWidth startIcon={<Icon name='fa-ban' />} onClick={onClose}>
                {t('ui_cancel')}
              </Button>
            </>
          )}

          {inProgessOrFailed && (
            <Button variant="contained" fullWidth startIcon={<Icon name="fa-arrow-left" />} onClick={onClose}>
              {t('UI_Common_back')}
            </Button>
          )}

          {success && (
            <Button variant="contained" fullWidth startIcon={<Icon name="fa-check" />} onClick={onClose}>
              {t('UI_Common_Ok')}
            </Button>
          )}
        </div>
      <div id="overlay-portal"></div>

    </>
  );
};

export default QrActivate;
