import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import React, { FC, useEffect, useState } from 'react';
import Card from '$components/cards/card/card.react';
import CardContent from '$components/cards/card-content/card-content.react';
import { SignalIcon } from "$components/icons/signal-icon/signal-icon";
import { SignalEnum } from "$typings/graphql";
import { useQuery } from "$lib/hooks/fetch-utillities";
import { SimStatusByControllerSerialDocument } from "$typings/graphql-codegen";
import ErrorText from "$components/texts/error-text/error-text.react";
import QrActivate from "$pages/qr/modules/qr.activate";
import { getSession } from "../../config/sessionService";
import { userHasFeature } from "$lib/authorizationHelper";
import { features } from "../../config";
import { AccessLevel } from "$interfaces/enums";
import "./qr.css"
import "../common/global.css"
import { Button, Divider } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import TopBar from '$components/topbar/topbar.react';
import { AuthenticationService } from '$services/authenticationService';

interface InfoRowProps {
  left: React.ReactNode,
  right: React.ReactNode
}

const InfoRow: FC<InfoRowProps> = ({ left, right }) => (<div>
  <div className='flex jsb pad_bs gap-3'>
    <div className="nowrap">{left}</div>
    <div>{right}</div>
  </div>
  <Divider />
</div>)

enum QrAppModeEnum {
  Home,
  Documentation,
  SimActivation,
}

interface QrAppProps {
  authService: AuthenticationService
}

const Qr: FC<QrAppProps> = ({ authService }) => {
  const [t] = useCaseInsensitiveTranslation();
  const serial = new URL(window.location.href).pathname.split("/").pop(); //Serial is after last / in path
  const modeFromQuery = new URL(window.location.href.replace("/" + serial, '')).pathname.split("/").pop();
  const { data, error, loading } = useQuery(SimStatusByControllerSerialDocument, { serial: serial });
  const [mode, setMode] = useState<QrAppModeEnum>(QrAppModeEnum.Home);
  const [enableActivation, setEnableActivation] = useState(false);
  const session = getSession();
  const userIsLoggedIn = session.userIsLoggedIn;

  useEffect(() => {
    if (loading) return;
    const isActivated = data?.simStatusByControllerSerial?.simStatus === "Active" || data?.simStatusByControllerSerial?.lastContact;
    const isLoggedInWithAccess = session.userIsLoggedIn && userHasFeature(session, features.qrApp, AccessLevel.Write);
    const isNotLoggedIn = !session.userIsLoggedIn;

    //Enable activation button if SIM not already activated and user is
    //either logged in with activation access
    //or not logged in (at which case clicking the button will login the user)
    if (!isActivated && (isLoggedInWithAccess || isNotLoggedIn)) {
      setEnableActivation(true);
      if (modeFromQuery == "activate") {
        setMode(QrAppModeEnum.SimActivation);
      }
    }
  }, [data]);


  function toSignalText(signal: SignalEnum) {
    switch (signal) {
      case SignalEnum.Online:
        return "Online";
      case SignalEnum.Stale:
        return "Stale";
    }

    return "Offline";
  }

  function navigate(url: string) {
    window.location.href = url;
  }

  function toSignal(lastContact: string | null | undefined) {
    if (!lastContact)
      return SignalEnum.Offline;

    return SignalEnum.Online;
  }

  function goToActivationView() {
    if (session.userIsLoggedIn && userHasFeature(session, features.qrApp, AccessLevel.Write)) {
      setMode(QrAppModeEnum.SimActivation)
    } else if (session.userIsLoggedIn && !userHasFeature(session, features.qrApp, AccessLevel.Write)) {
      //Logged in, no access to activation
      setEnableActivation(false);
    } else {
      authService.login();
    }
  }

  function getControllerImage(controllerType?: string | null) {
    const loweredCaseControllerType = controllerType?.toLowerCase();

    switch (loweredCaseControllerType) {
      case "ida211":
      case "ida-211":
        return "images/ida211.png";
      case "ida11":
      case "ida-11":
        return "images/ida11.png"
      default:
        return "";
    }

  }

  return (
    <>
      <TopBar>
        <div className="topbar-logo-wrapper">
          <a className="company-logo" href={'/qr/' + serial}>
            <img src="/images/logo/logo-small.svg" alt="Yara logo" />
          </a>
          <p className="topbar-title">{t('ui_qr_title')}</p>
        </div>
      </TopBar>
      <Card className='mar_m'>
        <CardContent className='flex column gap-1' >
          <div><b>{data?.simStatusByControllerSerial.controllerName}</b></div>
          <InfoRow left="Device type" right={data?.simStatusByControllerSerial?.controllerType} />
          <InfoRow left="Serial number" right={serial} />
          <InfoRow left="ICC" right={data?.simStatusByControllerSerial.simIcc} />
          {data?.simStatusByControllerSerial?.simIpAddress && (<InfoRow left="IpAdress" right={data?.simStatusByControllerSerial.simIpAddress} />)}
          <InfoRow left="SIM state" right={data?.simStatusByControllerSerial.simStatus} />
          <InfoRow left="Comms state" right={<div className='center-content'><SignalIcon signal={toSignal(data?.simStatusByControllerSerial?.lastContact)} /> {toSignalText(toSignal(data?.simStatusByControllerSerial?.lastContact))}</div>} />
          {data?.simStatusByControllerSerial.siteName && (<InfoRow left="Site" right={data?.simStatusByControllerSerial.siteName} />)}
          {data && (
            <div className='center-content'>
              <img src={getControllerImage(data.simStatusByControllerSerial.controllerType)} width={100} />
            </div>
          )}

          {error && (
            <ErrorText>
              Either controller or SIM is not known to the system. Please contact Yara Telemetry support for futher assistance. Serial: {serial}
            </ErrorText>
          )}

          {mode == QrAppModeEnum.SimActivation && (
            <QrActivate data={data}
              onClose={() => setMode(QrAppModeEnum.Home)}
            />
          )}

          {(!error && mode == QrAppModeEnum.Home) && (
            <div className='flex column gap-1'>
              <Button fullWidth variant="outlined" startIcon={<Icon name="fa-lock" />}
                disabled={!enableActivation}
                onClick={() => goToActivationView()}>{t('ui_qr_nav_sim_activation')}</Button>

              {/*Only show link to site details if site is specified*/}
              {data?.simStatusByControllerSerial?.siteId && (
                <Button variant="outlined" startIcon={<Icon name="fa-unlock" />}
                  onClick={() => navigate('/sitedetails/' + data?.simStatusByControllerSerial?.siteId)}>{t('ui_qr_nav_site')}</Button>
              )}

              {/*Only show link to controllermanager if site is specified*/}
              {data?.simStatusByControllerSerial?.controllerId && (
                <Button fullWidth variant="outlined" startIcon={<Icon name="fa-unlock" />}
                  onClick={() => navigate('/controllermanager/' + data?.simStatusByControllerSerial?.controllerId)}>{t('ui_qr_nav_controller')}</Button>
              )}

              {!userIsLoggedIn && (
                <Button fullWidth variant="outlined" startIcon={<Icon name="fa-unlock" />}
                  onClick={() => authService.login()}>{t('ui_qr_nav_login')}</Button>
              )}

              {userIsLoggedIn && (
                <>
                  <Button fullWidth variant="outlined" startIcon={<Icon name="fa-home" />}
                    onClick={() => navigate(`/`)}>{t('ui_qr_nav_yt')}</Button>

                  <Button fullWidth variant="outlined" startIcon={<Icon name="f-unlock" />}
                    onClick={() => authService.logout()}>{t('UI_Common_Logout')}</Button>
                </>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Qr;
